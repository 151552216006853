import React, { useEffect, useMemo } from "react";
import {
	useControlField,
	useField,
	useFormContext,
} from "remix-validated-form";

export function useValidatorFields(name, formId) {
	const validatorFormId = `${formId}_VALIDATOR`;

	const { validate: mainValidate } = useField(name, {
		formId: formId,
	});
	const {
		error,
		getInputProps,
		validate: validatorValidate,
	} = useField(name, {
		formId: validatorFormId,
	});

	return {
		error,
		getInputProps,
		validatorValidate,
		mainValidate,
	};
}

export function useValidateOnChange(name: string, formId: string) {
	const [value] = useControlField(name, formId);

	const { validatorValidate, mainValidate } = useValidatorFields(name, formId);
	useEffect(() => {
		if (value) {
			mainValidate();
			validatorValidate();
		}
	}, [mainValidate, validatorValidate, value]);
}
export function useValidatorField<T extends string | boolean | string[]>(
	name,
	formId,
) {
	const validatorFormId = `${formId}_VALIDATOR`;

	const {
		error: mainFormError,
		getInputProps: mainFormGetInputProps,
		validate,
		setTouched,
		touched,
	} = useField(name, {
		formId: formId || "undetermined-form",
	});
	const [_value, _setValue] = useControlField<string>(
		name,
		formId || "undetermined-form",
	);
	const [_valueValidator, _setValueValidator] = useControlField<string>(
		name,
		validatorFormId || "undetermined-form",
	);

	const { mainValidate, validatorValidate, error, getInputProps } =
		useValidatorFields(name, formId);
	const [_v, setValidatorValue] = useControlField<T>(name, validatorFormId);

	const { defaultValues } = useFormContext(validatorFormId);

	const isFormInitialized = !!defaultValues;

	useEffect(() => {
		// console.log({ seen: name, value: _v, formId: validatorFormId });
		if (isFormInitialized && _v !== undefined) validatorValidate();
	}, [_v, isFormInitialized, validatorValidate]);

	const validatedInputEl = useMemo(() => {
		const inputs =
			typeof _v === "boolean"
				? {
						type: "checkbox",
						className: "hidden",
						checked: !!_v,
						readOnly: true,
				  }
				: {
						type: "hidden",
						value: _v ?? "",
				  };
		return (
			<input
				form={validatorFormId}
				{...getInputProps({
					...inputs,
				} as any)}
			/>
		);
	}, [_v, getInputProps, validatorFormId]);

	return {
		_value,
		_setValue,
		mainFormError,
		mainFormGetInputProps,
		setValidatorValue,
		getValidatorInputProps: getInputProps,
		error,
		validatorFormId,
		validatedInputEl,
		validatorValidate,
		mainValidate,
		isFormInitialized,
		_valueValidator,
		_setValueValidator,
	};
}

export function useValidatorBooleanField(name, formId) {
	const validatorFormId = `${formId}_VALIDATOR`;

	const { error: mainFormError, getInputProps: mainFormGetInputProps } =
		useField(name, {
			formId: formId || "undetermined-form",
		});
	const [_value, _setValue] = useControlField<boolean>(
		name,
		formId || "undetermined-form",
	);
	const [_valueValidator, _setValueValidator] = useControlField<boolean>(
		name,
		validatorFormId || "undetermined-form",
	);

	const { mainValidate, validatorValidate, error, getInputProps } =
		useValidatorFields(name, formId);
	const [_v, setValidatorValue] = useControlField<boolean>(
		name,
		validatorFormId,
	);

	const { defaultValues } = useFormContext(validatorFormId);

	const isFormInitialized = !!defaultValues;

	useEffect(() => {
		// console.log({ seen: name, value: _v, formId: validatorFormId });
		if (isFormInitialized && _v !== undefined) validatorValidate();
	}, [_v, isFormInitialized, validatorValidate]);

	const validatedInputEl = useMemo(() => {
		return (
			<input
				form={validatorFormId}
				checked={!!_v}
				type={"checkbox"}
				className={"hidden"}
				readOnly={true}
				{...getInputProps({})}
			/>
		);
	}, [_v, getInputProps, validatorFormId]);

	return {
		_value,
		_setValue,
		mainFormError,
		mainFormGetInputProps,
		setValidatorValue,
		getValidatorInputProps: getInputProps,
		error,
		validatorFormId,
		validatedInputEl,
		validatorValidate,
		mainValidate,
		isFormInitialized,
		_valueValidator,
		_setValueValidator,
	};
}

export const useValidateFormLogic = (
	name: string,
	formId: string,
	value: string,
) => {
	const {
		_value,
		_setValue,
		mainFormGetInputProps,
		mainValidate,
		setValidatorValue,
		validatorValidate,
		isFormInitialized,
	} = useValidatorField<string>(name, formId);
	useEffect(() => {
		if (value !== undefined) {
			_setValue(value);
			mainValidate();
		}
	}, [_setValue, mainValidate, value]);

	useEffect(() => {
		setValidatorValue(_value);
		if (isFormInitialized) {
			validatorValidate();
		}
	}, [_value, setValidatorValue, isFormInitialized, validatorValidate]);
};
